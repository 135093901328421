<template>
  <div class="knowledge">
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      :offset="10"
    >
      <van-cell class="content" v-for="(item,index) in tableListData" :key="index" @click="getVideo(item)">
        <div  class="contenRight">
          <div class="contentflex">
              <a>
                <img src="@/assets/video.png" alt="" class="videoImgs" v-if="item.video_url!==''">
                <img :src="item.image" alt="" class="imgHead">
              </a>
              <h4>{{item.title}}</h4>
              <strong class="flexDispay">
                {{item.time}}
              </strong>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import {
  getCultureList,
} from "@/api/index";

export default {
  components: {
  },
  data(){
    return{
      tableListData:[],
      videoStart:false,
      SrcUrl:'',
      loading:false,
      finished:false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total:0,
    }
  },
  created(){
    this.initTable();
  },
  methods:{
    initTable(){
      let datas = {
        page:this.page,
        type:2,
        categoryId:this.$route.query.idType ? Number(this.$route.query.idType) : 1
      }
      getCultureList(datas).then(res=>{
        this.tableFilter = res.data.data;
        this.total = res.data.cultureList.total;
        this.loading = false;
        if (res.data.cultureList.data == null || res.data.cultureList.data.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }

        // 将新数据与老数据进行合并
        this.tableListData = this.tableListData.concat(res.data.cultureList.data);
       
       //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.tableListData.length >= this.total) {
          this.finished = true;
        }
      })
    },
    getVideo(row){
      this.SrcUrl = row.video_url
      if(row.video_url==''){
        this.$router.push({
          name:'knowledgeDetails',
          query:{
            id:row.id
          }
        })
      }else{
        this.$router.push({
          name:'video',
          query:{
            urlVideo:row.video_url
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.knowledge{
  background: #fff;
}
.imgHead{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.contenRight{
    display: flex;
    justify-content: center;
    padding: 10px;
    .contentflex{
        flex:1;
        width: 100%;
        h4{
            padding:12px 0 0 9px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
            word-break: break-all;
        }
        a{
          width: 100%;
          height: 74%;
          display: inline-block;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
    }
    .videoImgs{
      position: absolute;
      width: 43px;
    }
}
.flexDispay{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #777;
    padding: 10px 0 0 12px;
}
.content{
  padding: 0;
}
</style>
